import * as React from "react"
import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import { JsonDebugger } from "../../components/json-debugger"
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { Link, navigate } from "gatsby"
// import { loginForm } from "./account.module.less"
import { StoreContext } from "../../context/store-context"
import PageContent from "../../components/page-content"

const { Title, Link: AntLink } = Typography

const AccountPage: React.FC = (data) => {
  const title = "Login"
  const { login, loading, customerData } = React.useContext(StoreContext)

  const onFinish = (values: any) => {
    // console.log("Success:", values)
    login(values.email, values.password)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }
  return (
    <Layout>
      <Seo title={title} />
      <PageContent>
        <Row justify="center">
          <Col md={12}>
            <Card title={<Title>{title}</Title>}>
              <Form
                name="login"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                // className={loginForm}
              >
                <Form.Item label="Email">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email address",
                      },
                    ]}
                  >
                    <Input prefix={<UserOutlined />} />
                  </Form.Item>
                </Form.Item>
                <Form.Item label="Password">
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password",
                        min: 5,
                      },
                    ]}
                  >
                    <Input.Password prefix={<LockOutlined />} />
                  </Form.Item>
                </Form.Item>

                {/* <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox>Remember me</Checkbox>
            <Link to="/account/reset">Forgot password</Link>
          </Form.Item> */}

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Log in
                  </Button>{" "}
                  {/* Or <Link to={"/account/create"}>register now</Link> */}
                  {/* <Button
                    type="link"
                    onClick={() => navigate("/account/create")}
                  >
                    Register
                  </Button> */}
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Space>
                    {/* <Button
                    type="link"
                    onClick={() => navigate("/account/reset")}
                  >
                    Forgot password?
                  </Button> */}
                    <Link to="/account/reset">Forgot password?</Link>
                    <Link to="/account/create">Register</Link>
                  </Space>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
        {/* <JsonDebugger json={{ loading, customerData, data }} /> */}
      </PageContent>
    </Layout>
  )
}

export default AccountPage
